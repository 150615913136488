import React from 'react'
import { createMulticall } from '@uniswap/redux-multicall'
import { useInterfaceMulticall } from 'hooks/useContract'
import useBlockNumber from '../useBlockNumber'
import { combineReducers, createStore } from 'redux'
import { useActiveNetworkVersion } from 'state/application/hooks'

const multicall = createMulticall()
const reducer = combineReducers({ [multicall.reducerPath]: multicall.reducer })
export const store = createStore(reducer)

export default multicall

export function MulticallUpdater() {
  const latestBlockNumber = useBlockNumber()
  const [activeNetwork] = useActiveNetworkVersion()
  const chainId = activeNetwork.id;
  const contract = useInterfaceMulticall()
  return <multicall.Updater chainId={chainId} latestBlockNumber={latestBlockNumber} contract={contract} />
}
