import { AssetTokenData } from "data/assetvault";
import { ethers } from "ethers";
import UniswapV2VaultABI from 'constants/abis/UniswapV2Vault.json'
import { RPC_PROVIDERS, UNISWAP_V2_VAULT_ADDRESS, UNISWAP_V3_VAULT_ADDRESS } from 'constants/index'
import { useActiveNetworkVersion } from "state/application/hooks";
import { useSingleContractMultipleData } from "lib/hooks/multicall";

export default function useTokenPriceList(allFetchedTokens : AssetTokenData[]) {
    const [activeNetwork] = useActiveNetworkVersion()
    const provider = new ethers.providers.JsonRpcProvider(RPC_PROVIDERS[activeNetwork.id])
    const contract = new ethers.Contract(UNISWAP_V2_VAULT_ADDRESS[activeNetwork.id], UniswapV2VaultABI, provider)
    const inputs = allFetchedTokens.map((token) => [token.tokenAddress, token.amount + "0000"]);
    const tokenPriceList = useSingleContractMultipleData(contract, 'convertCapitalToUSD', inputs, {blocksPerFetch: 1000});
    
    const contractV3 = new ethers.Contract(UNISWAP_V3_VAULT_ADDRESS[activeNetwork.id], UniswapV2VaultABI, provider)
    const tokenPriceListV3= useSingleContractMultipleData(contractV3, 'convertCapitalToUSD', inputs, {blocksPerFetch: 1000});
    return { v2: tokenPriceList, v3: tokenPriceListV3};
}

