import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { Label, ClickableText } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { networkPrefix } from 'utils/networkPrefix'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { AMMData } from 'data/ammvault'
import { ethers } from 'ethers'
import UniswapV2VaultABI from 'constants/abis/UniswapV2Vault.json'
import { AMMS_PER_NETWORK, RPC_PROVIDERS, UNISWAP_V2_VAULT_ADDRESS } from 'constants/index'

const Wrapper = styled.div`
  width: 100%;
`

const SortText = styled.button<{ active: boolean }>`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  opacity: ${({ active }) => (active ? 1 : 0.45)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;

  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ active }) => (active ? 'transparent' : '')};
  background-clip: text;
  text-fill-color: transparent;

  font-size: 18px;
  line-height: 28px;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : 'white')};
  outline: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3.5fr repeat(1, 1fr);
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const SORT_FIELD = {
  capitalSymbol: 'capitalToken.symbol',
  feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  capital: 'capital',
  volumeUSDWeek: 'volumeUSDWeek',
}

const DataRow = ({ pairData, index }: { pairData: AMMData; index: number }) => {
  const [activeNetwork] = useActiveNetworkVersion()
  const [tvl, setTvl] = useState(0)

  useEffect(() => {
    setTvl(0);
    const getTvl = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(RPC_PROVIDERS[activeNetwork.id])
        const contract = new ethers.Contract(UNISWAP_V2_VAULT_ADDRESS[activeNetwork.id], UniswapV2VaultABI, provider)
        const tvl = await contract.convertCapitalToUSD(pairData.capitalAddress, pairData.amount + "0000")
        setTvl(Number(tvl / 10000) * 2)
      } catch (e) {}
    }
    getTvl()
  }, [pairData.amount, pairData.capitalAddress, activeNetwork])

  return (
    // <LinkWrapper to={networkPrefix(activeNetwork) + 'pools/' + pairData.pairAddress}>
      <ResponsiveGrid>
        <Label fontWeight={400}>{index + 1}</Label>
        <Label fontWeight={400}>
          <RowFixed>
            <TYPE.label ml="8px">
              {pairData.capitalToken.symbol}/{pairData.assetToken.symbol} ({pairData.ammType})
            </TYPE.label>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400}>
          {tvl > 0 ? formatDollarAmount(tvl) : "-"}
        </Label>
      </ResponsiveGrid>
    // </LinkWrapper>
  )
}

const MAX_ITEMS = 10

export default function PairTables({
  pairDatas,
  maxItems = MAX_ITEMS,
  loading,
}: {
  pairDatas: AMMData[]
  maxItems?: number
  loading: boolean
}) {
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortType, setSortType] = useState("Float")
  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [activeNetwork] = useActiveNetworkVersion()
  const ammList = AMMS_PER_NETWORK[activeNetwork.id] ?? [];
  useEffect(() => {
    let extraPages = 1
    if (pairDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(pairDatas.length / maxItems) + extraPages)
  }, [maxItems, pairDatas])

  const [ammFilter, setAmmFilter] = useState<string | undefined>(undefined)

  const sortedPairs = useMemo(() => {
    return pairDatas
      ? pairDatas
          .filter((pair) => {
            return ammFilter ? pair.ammType === ammFilter : true
          })
          // .sort((a, b) => {
          //   if (a && b) {
          //     return sortType == "String" ? a[sortField as keyof AMMData] > b[sortField as keyof AMMData] : parseFloat(a[sortField as keyof AMMData]) >parseFloat(b[sortField as keyof AMMData])
          //       ? (sortDirection ? -1 : 1) * 1
          //       : (sortDirection ? -1 : 1) * -1
          //   } else {
          //     return -1
          //   }
          // })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, pairDatas, ammFilter, sortType, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string, type: string) => {
      setSortField(newField)
      setSortType(type);
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!pairDatas) {
    return <Loader />
  }

  return (
    <Wrapper style={{ marginTop: 16 }}>
      {!loading ? (
        <AutoColumn gap="16px">
          <RowFixed>
            <SortText
              onClick={() => {
                setAmmFilter(undefined)
              }}
              active={ammFilter === undefined}
            >
              All
            </SortText>
            {
              ammList.map((amm, i) => {
                return (
                  <SortText onClick={() => setAmmFilter(amm)} active={ammFilter === amm} key={i}>
                    {amm}
                  </SortText>
                )
              })
            }
          </RowFixed>
          <ResponsiveGrid>
            <Label color={'white'}>#</Label>
            {/* <ClickableText color={'white'} onClick={() => handleSort(SORT_FIELD.capitalSymbol, "String")}> */}
            <ClickableText color={'white'}>
              Token Pair {arrow(SORT_FIELD.capitalSymbol)}
            </ClickableText>
            {/* <ClickableText color={'white'} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD, "Float")}> */}
            <ClickableText color={'white'} end={1}>
              TVL
            </ClickableText>
          </ResponsiveGrid>
          <Break />
          {sortedPairs.map((pairData, i) => {
            if (pairData) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} pairData={pairData} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + (maxPage === 0 ? 1 : maxPage)}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
