import { BigNumber } from '@ethersproject/bignumber'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import { SupportedChainId } from './chains'

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

export const MATIC_ADDRESS = '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
export const CELO_ADDRESS = '0x471EcE3750Da237f93B8E339c536989b8978a438'

const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
const ARBITRUM_WETH_ADDRESS = '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'

export const WETH_ADDRESSES = [WETH_ADDRESS, ARBITRUM_WETH_ADDRESS]

// temporary! fixing USD accounting on subgraph - open issue if urgent
export const TOKEN_HIDE = [
  '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
  '0x7dfb72a2aad08c937706f21421b15bfc34cba9ca',
  '0x12b32f10a499bf40db334efe04226cca00bf2d9b',
  '0x160de4468586b6b2f8a92feb0c260fc6cfc743b1',
]
export const POOL_HIDE = [
  '0x86d257cdb7bc9c0df10e84c8709697f92770b335',
  '0xf8dbd52488978a79dfe6ffbd81a01fc5948bf9ee',
  '0x8fe8d9bb8eeba3ed688069c3d6b556c9ca258248',
  '0xa850478adaace4c08fc61de44d8cf3b64f359bec',
  '0x277667eb3e34f134adf870be9550e9f323d0dc24',
  '0x8c0411f2ad5470a66cb2e9c64536cfb8dcd54d51',
  '0x055284a4ca6532ecc219ac06b577d540c686669d',
]

export const UNISWAP_V2_VAULT_ADDRESS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '0xF33e6A02aA5213e061016C01B0503CC94CB06985',
  [SupportedChainId.CELO]: '0xBf212dEE0aea6531dEb0B02be6E70b527dDF8246',
  [SupportedChainId.ARBITRUM_ONE]: '0xBf212dEE0aea6531dEb0B02be6E70b527dDF8246',
  [SupportedChainId.AURORA]: '0x5BD6BC30D3850e9D7AF44DE071dEf2BE5fc2bEf7',
}

export const UNISWAP_V3_VAULT_ADDRESS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '0xF33e6A02aA5213e061016C01B0503CC94CB06985',
  [SupportedChainId.CELO]: '0xE4ad5F3faD1c8f43b3D0ed59bdC528ba43E19B5d',
  [SupportedChainId.ARBITRUM_ONE]: '0x07116C5ED5cBb49464f64926Ba152B8985fe3AFf',
  [SupportedChainId.AURORA]: '0x5BD6BC30D3850e9D7AF44DE071dEf2BE5fc2bEf7',
}

export const BaseUSD :  { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  [SupportedChainId.CELO]: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  [SupportedChainId.ARBITRUM_ONE]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  [SupportedChainId.AURORA]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
}

export const RPC_PROVIDERS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: 'https://eth-goerli.public.blastapi.io',
  [SupportedChainId.CELO]: 'https://celo-mainnet.infura.io/v3/a1ac3a707d90454eb92624852b96e497',
  [SupportedChainId.ARBITRUM_ONE]: 'https://arb1.arbitrum.io/rpc',
  [SupportedChainId.AURORA]: 'https://testnet.aurora.dev',
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true,
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true,
  },
}

export const NetworkContextName = 'NETWORK'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]

export const AMMS_PER_NETWORK = {
  // [SupportedChainId.MAINNET]: ['UniswapV2', 'SushiSwap'],
  // [SupportedChainId.GOERLI]: ['UniswapV2', 'UniswapV3', 'SushiSwap'],
  // [SupportedChainId.KOVAN]: ['UniswapV2', 'SushiSwap'],
  [SupportedChainId.CELO]: ['Ubeswap', 'UniswapV3'],

  // [SupportedChainId.CELO]: [],
  // [SupportedChainId.AURORA]: ['Trisolaris'],
  // [SupportedChainId.AURORA_TEST]: ['Trisolaris'],
  // [SupportedChainId.OPTIMISM]: [],
  // [SupportedChainId.OPTIMISTIC_KOVAN]: [],
  // [SupportedChainId.ARBITRUM_ONE]: [],
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.AURORA]: [],
  [SupportedChainId.ARBITRUM_ONE]: ['UniswapV2', 'UniswapV3'],
  // [SupportedChainId.POLYGON]: ['Trisolaris'],
  // [SupportedChainId.POLYGON_MUMBAI]: ['PancakeV2'],
}

