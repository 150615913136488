import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useDeltaTimestamps } from 'utils/queries'
import { useBlocksFromTimestamps } from 'hooks/useBlocksFromTimestamps'
import { PoolData } from 'state/pools/reducer'
import { get2DayChange } from 'utils/data'
import { formatTokenName, formatTokenSymbol } from 'utils/tokens'
import { useActiveNetworkVersion, useClients } from 'state/application/hooks'

export const POOLS_BULK = (block: number | undefined, pools: string[]) => {
  let poolString = `[`
  pools.map((address) => {
    return (poolString += `"${address}",`)
  })
  poolString += ']'
  const queryString =
    `
    query pools {
      histories(where: {capital_in: ${poolString}},` +
    (/* block ? `block: {number: ${block}} ,` :  */``) +
    ` orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
        id
        owner
        asset
        capital
        lpAmount
        action
        ammType
        bundle
        capitalAmount
        assetAmount
        timestamp
      }
      tokens {
        decimal
        id
        name
        symbol
      }
    }
    `
  return gql(queryString)
}

interface PoolFields {
  id: string
  owner: string
  asset: string
  capital: string
  lpAmount: string
  action: string
  ammType: string
  bundle: string
  capitalAmount: string
  assetAmount: string
  timestamp: string
}

interface PoolDataResponse {
  histories: PoolFields[]
  tokens: {
    decimal: number
    id: string
    name: string
    symbol: string
  }[]
}

/**
 * Fetch top addresses by volume
 */
export function usePoolDatas(
  poolAddresses: string[]
): {
  loading: boolean
  error: boolean
  data:
    | {
        [address: string]: PoolData
      }
    | undefined
} {
  // get client
  const { dataClient } = useClients()
  const [activeNetwork] = useActiveNetworkVersion()

  // get blocks from historic timestamps
  const [t24, t48, tWeek] = useDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48, tWeek])
  const [block24, block48, blockWeek] = blocks ?? []
  
  const { loading, error, data } = useQuery<PoolDataResponse>(POOLS_BULK(undefined, poolAddresses), {
    client: dataClient,
  })
  
  const { loading: loading24, error: error24, data: data24 } = useQuery<PoolDataResponse>(
    POOLS_BULK(block24?.number, poolAddresses),
    { client: dataClient }
  )
  const { loading: loading48, error: error48, data: data48 } = useQuery<PoolDataResponse>(
    POOLS_BULK(block48?.number, poolAddresses),
    { client: dataClient }
  )
  const { loading: loadingWeek, error: errorWeek, data: dataWeek } = useQuery<PoolDataResponse>(
    POOLS_BULK(blockWeek?.number, poolAddresses),
    { client: dataClient }
  )

  const anyError = Boolean(error || error24 || error48 || blockError || errorWeek)
  const anyLoading = Boolean(loading || loading24 || loading48 || loadingWeek)

  // return early if not all data yet
  if (anyError || anyLoading) {
    return {
      loading: anyLoading,
      error: anyError,
      data: undefined,
    }
  }

  // const ethPriceUSD = data?.bundles?.[0]?.ethPriceUSD ? parseFloat(data?.bundles?.[0]?.ethPriceUSD) : 0

  const parsed = data?.histories
    ? data.histories.reduce((accum: { [address: string]: PoolFields }, poolData) => {
        accum[poolData.capital] = poolData
        return accum
      }, {})
    : {}
  const parsed24 = data24?.histories
    ? data24.histories.reduce((accum: { [address: string]: PoolFields }, poolData) => {
        accum[poolData.capital] = poolData
        return accum
      }, {})
    : {}
  const parsed48 = data48?.histories
    ? data48.histories.reduce((accum: { [address: string]: PoolFields }, poolData) => {
        accum[poolData.capital] = poolData
        return accum
      }, {})
    : {}
  const parsedWeek = dataWeek?.histories
    ? dataWeek.histories.reduce((accum: { [address: string]: PoolFields }, poolData) => {
        accum[poolData.capital] = poolData
        return accum
      }, {})
    : {}

  // format data and calculate daily changes
  const formatted = poolAddresses.reduce((accum: { [address: string]: PoolData }, address) => {
    const current: PoolFields | undefined = parsed[address]
    const oneDay: PoolFields | undefined = parsed24[address]
    const twoDay: PoolFields | undefined = parsed48[address]
    const week: PoolFields | undefined = parsedWeek[address]

    /* const [volumeUSD, volumeUSDChange] =
      current && oneDay && twoDay
        ? get2DayChange(current.volumeUSD, oneDay.volumeUSD, twoDay.volumeUSD)
        : current
        ? [parseFloat(current.volumeUSD), 0]
        : [0, 0]

    const volumeUSDWeek =
      current && week
        ? parseFloat(current.volumeUSD) - parseFloat(week.volumeUSD)
        : current
        ? parseFloat(current.volumeUSD)
        : 0 */

    // Hotifx: Subtract fees from TVL to correct data while subgraph is fixed.
    /**
     * Note: see issue desribed here https://github.com/Uniswap/v3-subgraph/issues/74
     * During subgraph deploy switch this month we lost logic to fix this accounting.
     * Grafted sync pending fix now.
     */
    /* const feePercent = current ? parseFloat(current.feeTier) / 10000 / 100 : 0
    const tvlAdjust0 = current?.volumeToken0 ? (parseFloat(current.volumeToken0) * feePercent) / 2 : 0
    const tvlAdjust1 = current?.volumeToken1 ? (parseFloat(current.volumeToken1) * feePercent) / 2 : 0
    const tvlToken0 = current ? parseFloat(current.totalValueLockedToken0) - tvlAdjust0 : 0
    const tvlToken1 = current ? parseFloat(current.totalValueLockedToken1) - tvlAdjust1 : 0
    let tvlUSD = current ? parseFloat(current.totalValueLockedUSD) : 0

    const tvlUSDChange =
      current && oneDay
        ? ((parseFloat(current.totalValueLockedUSD) - parseFloat(oneDay.totalValueLockedUSD)) /
            parseFloat(oneDay.totalValueLockedUSD === '0' ? '1' : oneDay.totalValueLockedUSD)) *
          100
        : 0

    // Part of TVL fix
    const tvlUpdated = current
      ? tvlToken0 * parseFloat(current.token0.derivedETH) * ethPriceUSD +
        tvlToken1 * parseFloat(current.token1.derivedETH) * ethPriceUSD
      : undefined
    if (tvlUpdated) {
      tvlUSD = tvlUpdated
    }

    const feeTier = current ? parseInt(current.feeTier) : 0 */
    if (current) {
      const asset = data?.tokens.find(token => token.id === current.asset)
      const capital = data?.tokens.find(token => token.id === current.capital)
      accum[address] = {
        address,
        feeTier: 0,
        liquidity: 0,
        sqrtPrice: 0,
        tick: 0,
        token0: {
          address: current.asset,
          name: asset?.name || 'token0',
          symbol: asset?.symbol || 'symbol0',
          decimals: asset?.decimal || 18,
          derivedETH: 0,
        },
        token1: {
          address: current.capital,
          name: capital?.name || 'token0',
          symbol: capital?.symbol || 'symbol0',
          decimals: capital?.decimal || 18,
          derivedETH: 0,
        },
        token0Price: 0,
        token1Price: 0,
        volumeUSD: 0,
        volumeUSDChange: 0,
        volumeUSDWeek: 0,
        tvlUSD: 0,
        tvlUSDChange: 0,
        tvlToken0: 0,
        tvlToken1: 0,
      }
    }

    return accum
  }, {})

  return {
    loading: anyLoading,
    error: anyError,
    data: formatted,
  }
}
