import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'

const GLOBAL_TRANSACTIONS = gql`
  query transactions($address: Bytes!) {
    assetHistories(where: {asset: $address}, first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      timestamp
      owner
      asset
      amount
      type
      tvl
    }
    tokens {
      decimal
      id
      name
      symbol
    }
  }
`

interface TransactionResults {
  assetHistories: {
    id: string
    timestamp: string
    owner: string
    asset: string
    amount: string
    type: string
    tvl: string
  }[]
  tokens: {
    decimal: number
    id: string
    name: string
    symbol: string
  }[]
}

export async function fetchTokenTransactions(
  address: string,
  client: ApolloClient<NormalizedCacheObject>
): Promise<{ data: Transaction[] | undefined; error: boolean; loading: boolean }> {
  try {
    const { data, error, loading } = await client.query<TransactionResults>({
      query: GLOBAL_TRANSACTIONS,
      variables: {
        address: address,
      },
      fetchPolicy: 'cache-first',
    })

    if (error) {
      return {
        data: undefined,
        error: true,
        loading: false,
      }
    }

    if (loading && !data) {
      return {
        data: undefined,
        error: false,
        loading: true,
      }
    }

    const tokenData = data.tokens.map((token: any) => {
      return {
        address: token.id,
        name: token.name,
        decimal: token.decimal,
        symbol: token.symbol,
      }
    })
  
    const typeMap: any = {
      deposit: TransactionType.DEPOSIT,
      withdraw: TransactionType.WITHDRAW,
      borrow: TransactionType.BORROW,
      repay: TransactionType.REPAY,
      AddLiquidity: TransactionType.ADD_LIQUIDITY,
      RemoveLiquidity: TransactionType.REMOVE_LIQUIDITY,
      ImportLiquidity: TransactionType.IMPORT_LIQUIDITY,
      MigrateLiquidity: TransactionType.MIGRATE_LIQUIDITY,
    }

    const assetHistories = data.assetHistories.map((m) => {
      const token = tokenData.filter((item: any) => item.address === m.asset)[0]
      return {
        type: typeMap[m.type],
        hash: m.id,
        timestamp: m.timestamp,
        sender: m.owner,
        token0Decimal: token ? token.decimal : 18,
        token1Decimal: 18,
        token0Symbol: token ? token.symbol : 'USD',
        token1Symbol: '',
        token0Address: m.asset,
        token1Address: '',
        amountUSD: 0,
        amountToken0: m.amount,
        amountToken1: m.amount,
        lpAmount: undefined,
        tvl: m.tvl,
      }
    })

    return { data: [...assetHistories], error: false, loading: false }
  } catch {
    return {
      data: undefined,
      error: true,
      loading: false,
    }
  }
}
