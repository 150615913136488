// @ts-nocheck
import gql from 'graphql-tag'
import { useState, useEffect, useMemo } from 'react'
import { useAMMClient, useActiveNetworkVersion, useDataClient } from 'state/application/hooks'

import useTokenPriceList from 'hooks/useTokenPriceList'
import { BaseUSD } from 'constants/index'
import { formatUnits } from 'ethers/lib/utils'

export const ASSET_TOKENS = gql`
  query tokens {
    asTokens: assetTokens(orderBy: amount, orderDirection: desc) {
      id
      tokenAddress
      amount
      debt
    }
  }
`

export const TOKENS_QUERY = gql(`
  query tokens {
    tokens {
      decimal
      id
      name
      symbol
    }
  }
`)

const UNISWAP_TOKEN_PRICE = (tokens: string[]) => {
  let tokenString = `[`
  tokens.map((address) => {
    return (tokenString += `"${address}",`)
  })
  tokenString += ']'
  const queryString =
    `
    query tokens {
      tokens(where: {id_in: ${tokenString}}, subgraphError: allow) {
        id
        symbol
        derivedETH
      }
      current: bundles(first: 1, subgraphError: allow) {
        ethPriceUSD
      }
    }
    `
  return gql(queryString)
}

export interface AssetToken {
  id: string
  tokenAddress: string
  amount: string
  debt: string
}

export interface TokenMetadata {
  address: string
  name: string
  decimal: string
  symbol: string,
  networkId: number
}

export interface AssetTokenData {
  id: string
  tokenAddress: string
  amount: string
  debt: string
  name: string
  decimal: string
  symbol: string
  tvl?: string
  networkId: number
}

interface TokenRes {
  asTokens: AssetToken[]
}

export function useAssetVaultData(): {
  tokens: AssetTokenData[]
  tvlFromAsset: number
  loading: boolean
} {
  const infoClient = useDataClient()
  const [activeNetwork] = useActiveNetworkVersion()
  const [availableTokens, setAvailableTokens] = useState<TokenMetadata[]>([])

  const [tokenData, setTokenData] = useState<TokenRes | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [priceList, setPriceList] = useState<{[key: string]: number} | undefined>();

  const checkToken = (token0, token1, decimal) => {
    const token0Amount = parseFloat(formatUnits(token0, decimal)).toFixed(6);
    const token1Amount = parseFloat(formatUnits(token1, decimal)).toFixed(6);
    return token0Amount == token1Amount;
  };
  const ammClientList = useAMMClient();

  // fetch data based on search input
  useEffect(() => {
    async function fetch() {
      try {
        setAvailableTokens([])
        setTokenData(undefined)
        setLoading(true)
        const tokens = await infoClient.query<TokenRes>({
          query: ASSET_TOKENS,
        })

        if (tokens.data) {
          setTokenData(tokens.data)
        }

        const { data } = await infoClient.query({
          query: TOKENS_QUERY,
        })

        setAvailableTokens([
          ...data.tokens.map((token: any) => {
            return {
              address: token.id,
              name: token.name,
              decimal: token.decimal,
              symbol: token.symbol,
              networkId: activeNetwork.id,
            }
          }),
        ])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [infoClient, activeNetwork])

  const allFetchedTokens = useMemo(() => {
    if (tokenData && availableTokens.length > 0) {
      return [...tokenData.asTokens].map((token: AssetToken) => {
        const tokenMetadata = availableTokens.find((t) => t.address === token.tokenAddress)

        return {
          ...token,
          name: tokenMetadata?.name,
          symbol: tokenMetadata?.symbol,
          decimal: tokenMetadata?.decimal,
          networkId: tokenMetadata?.networkId,
        } as AssetTokenData
      })
    }
    return []
  }, [tokenData, availableTokens])


  const tokenPriceList = useTokenPriceList(allFetchedTokens);

  useEffect(() => {
    async function fetch() {
      try {
        const tokenArray = allFetchedTokens.map((item) => item.tokenAddress.toLowerCase());
        const price = new Object();
        const { data: uniswapPriceList } = await ammClientList[0].query<UniswapPriceResults>({
          query: UNISWAP_TOKEN_PRICE(tokenArray),
          fetchPolicy: 'cache-first',
        })
        const ethPrice = uniswapPriceList.current[0].ethPriceUSD;

        uniswapPriceList.tokens.forEach(function(item) {
          if(parseFloat(item.derivedETH) > 0 && price[item.id] == undefined) {
            price[item.id] = parseFloat(item.derivedETH) * parseFloat(ethPrice);
          }
        });
        setPriceList(price);
      } catch (e) {
        console.log(e)
      }
    }
    if(allFetchedTokens.length > 0) {
      fetch()
    }
  }, [allFetchedTokens])

  const tokenListWithTVL = useMemo(() => {
    if (allFetchedTokens && (tokenPriceList.v2.length > 0 || tokenPriceList.v3 != null) && priceList) {
      return allFetchedTokens.map((token: AssetTokenData, index) => {
        let tvl = 0;
        if(token.tokenAddress.toLowerCase() in priceList) {
          tvl = priceList[token.tokenAddress.toLowerCase()] * parseFloat(formatUnits(token.amount, token.decimal));
        } else {
          if(tokenPriceList.v3?.length > 0 && tokenPriceList.v3[index].valid == true && tokenPriceList.v3[index].loading == false) {
            if(checkToken(tokenPriceList.v3[index].result[0].toString() + "00", token.amount, token.decimal) && token.tokenAddress.toLocaleLowerCase() != BaseUSD[activeNetwork.id].toLowerCase()) {
            } else {
              tvl = (tokenPriceList.v3[index].result[0] / 10000).toString();
            }
          }
          if(tokenPriceList.v2[index].valid == true && tokenPriceList.v2[index].loading == false) {
            if(tokenPriceList.v2[index] && tokenPriceList.v2[index].result && tokenPriceList.v2[index].result?.length > 0)
            {
              if(checkToken(tokenPriceList.v2[index].result[0].toString() + "00", token.amount, token.decimal) && token.tokenAddress.toLocaleLowerCase() != BaseUSD[activeNetwork.id].toLowerCase()) {
              } else if( (tokenPriceList.v2[index].result[0] / 10000).toString() != "0") {
                const newTVL = tokenPriceList.v2[index].result[0] / 10000;
                tvl = tvl != 0 ? Math.min(parseFloat(tvl), newTVL).toString() : newTVL.toString();
              }
            }
          }
        }
        return {
          ...token,
          // @ts-ignore
          tvl: tvl,
        }
      })
    }
    return []
  }, [tokenPriceList, priceList])
  const tvlFromAsset = tokenListWithTVL.map(item => parseFloat(item.tvl)).reduce((acc, val) => acc + val, 0);

  return {
    tokens: tokenListWithTVL,
    tvlFromAsset: tvlFromAsset,
    loading: loading,
  }
}
