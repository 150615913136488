import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { TOKENS_QUERY } from 'data/protocol/transactions'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'

const POOL_TRANSACTIONS = gql`
  query transactions($address: Bytes!) {
    histories (first:100, orderBy: timestamp, where:{ capital:$address}, subgraphError: allow) {
      id
      owner
      asset
      capital
      lpAmount
      action
      ammType
      bundle
      capitalAmount
      assetAmount
      timestamp
    }
  }
`

interface TransactionResults {
  histories: {
    id: string
    owner: string
    asset: string
    capital: string
    lpAmount: string
    action: string
    ammType: string
    bundle: string
    capitalAmount: string
    assetAmount: string
    timestamp: string
  }[]
}

export async function fetchPoolTransactions(
  address: string,
  client: ApolloClient<NormalizedCacheObject>
): Promise<{ data: Transaction[] | undefined; error: boolean; loading: boolean }> {
  const { data, error, loading } = await client.query<TransactionResults>({
    query: POOL_TRANSACTIONS,
    variables: {
      address: address,
    },
    fetchPolicy: 'cache-first',
  })

  const { data: tokenMetadata } = await client.query({
    query: TOKENS_QUERY,
    fetchPolicy: 'cache-first',
  })

  const tokenData = tokenMetadata.tokens.map((token: any) => {
    return {
      address: token.id,
      name: token.name,
      decimal: token.decimal,
      symbol: token.symbol,
    }
  })

  if (error) {
    return {
      data: undefined,
      error: true,
      loading: false,
    }
  }

  if (loading && !data) {
    return {
      data: undefined,
      error: false,
      loading: true,
    }
  }

  const typeMap: any = {
    deposit: TransactionType.DEPOSIT,
    withdraw: TransactionType.WITHDRAW,
    borrow: TransactionType.BORROW,
    repay: TransactionType.REPAY,
    AddLiquidity: TransactionType.ADD_LIQUIDITY,
    RemoveLiquidity: TransactionType.REMOVE_LIQUIDITY,
    ImportLiquidity: TransactionType.IMPORT_LIQUIDITY,
    MigrateLiquidity: TransactionType.MIGRATE_LIQUIDITY,
  }

  const histories = data.histories.map((m) => {
    const token = tokenData.find((item: any) => item.address === m.asset)
    const capitalToken = tokenData.find((item: any) => item.address === m.capital)

    return {
      type: typeMap[m.action],
      hash: m.id,
      timestamp: m.timestamp,
      sender: m.owner,
      token0Decimal: capitalToken ? capitalToken.decimal : 18,
      token1Decimal: token ? token.decimal : 18,
      token0Symbol: capitalToken ? capitalToken.symbol : 'USD',
      token1Symbol: token ? token.symbol : 'USD',
      token0Address: m.asset,
      token1Address: m.capital,
      amountUSD: 0,
      amountToken0: m.capitalAmount,
      amountToken1: m.assetAmount,
      lpAmount: m.lpAmount,
      tvl: undefined,
    }
  })

  return { data: [...histories], error: false, loading: false }
}
