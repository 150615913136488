import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { formatAmount, formatDollarAmount } from 'utils/numbers'
import { Label, ClickableText } from 'components/Text'
import { Break } from 'components/shared'
import { useClients } from 'state/application/hooks'
import { CapitalData } from 'data/ammvault'
import { BigNumber, ethers } from 'ethers'

const Wrapper = styled.div`
  width: 100%;
`

const SortText = styled.button<{ active: boolean }>`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  opacity: ${({ active }) => (active ? 1 : 0.45)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;

  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ active }) => (active ? 'transparent' : '')};
  background-clip: text;
  text-fill-color: transparent;

  font-size: 18px;
  line-height: 28px;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : 'white')};
  outline: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3.5fr repeat(1, 1fr);
`

const DataRow = ({ capital, index }: { capital: CapitalData; index: number }) => {
  return (
    <ResponsiveGrid>
      <Label fontWeight={400}>{index + 1}</Label>
      <Label fontWeight={400}>
        <RowFixed>
          <TYPE.label ml="8px">{capital.capitalToken.name}</TYPE.label>
        </RowFixed>
      </Label>
      <Label end={1} fontWeight={400}>
        {formatAmount(Number(ethers.utils.formatUnits(BigNumber.from(capital.amount), capital.capitalToken.decimal || 18)))}
      </Label>
    </ResponsiveGrid>
  )
}

export default function CapitalTable({ capitalData, loading }: { capitalData: CapitalData[]; loading: boolean }) {
  if (!capitalData) {
    return <Loader />
  }

  return (
    <Wrapper style={{ marginTop: 16 }}>
      {!loading ? (
        <AutoColumn gap="16px">
          {/* <RowFixed>
            <SortText
              onClick={() => {
                setAmmFilter(undefined)
              }}
              active={ammFilter === undefined}
            >
              All
            </SortText>
            <SortText onClick={() => setAmmFilter('UniswapV2')} active={ammFilter === 'UniswapV2'}>
              UniswapV2
            </SortText>
            <SortText onClick={() => setAmmFilter('SushiSwap')} active={ammFilter === 'SushiSwap'}>
              Sushiswap
            </SortText>
          </RowFixed> */}
          <ResponsiveGrid>
            <Label color={'white'}>#</Label>
            <ClickableText color={'white'}>Capital Type</ClickableText>
            <ClickableText color={'white'} end={1}>
              Token Supply
            </ClickableText>
          </ResponsiveGrid>
          <Break />
          <React.Fragment>
            {
              capitalData.map((capital, index) => {
                return <DataRow key={index} capital={capital} index={index} />
              })
            }
            <Break />
          </React.Fragment>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
