import MULTICALL_ABI from './abi.json'
import { SupportedChainId } from 'constants/chains';

const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  [SupportedChainId.GOERLI]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.CELO]: '0x633987602DE5C4F337e3DbF265303A1080324204',
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
