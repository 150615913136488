import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { useMemo } from 'react'
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import { MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { UniswapInterfaceMulticall } from 'types/v3'
import { useActiveNetworkVersion } from 'state/application/hooks';
import { RPC_PROVIDERS } from 'constants/index';
import { SupportedChainId } from 'constants/chains';

const { abi: MulticallABI } = UniswapInterfaceMulticallJson

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const [activeNetwork] = useActiveNetworkVersion()
  const chainId = activeNetwork.id;
  const library = new ethers.providers.JsonRpcProvider(chainId ? RPC_PROVIDERS[chainId] : RPC_PROVIDERS[SupportedChainId.ARBITRUM_ONE])
  const account = null

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useInterfaceMulticall() {
  const [activeNetwork] = useActiveNetworkVersion()
  const chainId = activeNetwork.id;
  return useContract(chainId ? MULTICALL_NETWORKS[chainId] : undefined, MulticallABI, false) as UniswapInterfaceMulticall
}