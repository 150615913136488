import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { RowBetween } from 'components/Row'
import { useProtocolChartData, useProtocolData, useProtocolTransactions } from 'state/protocol/hooks'
import { formatDollarAmount } from 'utils/numbers'
import TokenTable from 'components/AssetTokens/TokenTable'
import PoolTables from 'components/pools/PPoolTables'
import PairTables from 'components/Pairs/PairTables'
import CapitalTable from 'components/Capital/CapitalTable'
import { PageWrapper, ThemedBackgroundGlobal } from 'pages/styled'
import { useAllPoolData } from 'state/pools/hooks'
import { notEmpty } from 'utils'
import TransactionsTable from '../../components/TransactionsTable'
import { useAllTokenData } from 'state/tokens/hooks'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { VolumeWindow } from 'types'
import { useAssetVaultData } from 'data/assetvault'
import { useAMMVaultData } from 'data/ammvault'

const DoubleContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%),
    linear-gradient(0deg, #131118, #131118);

  border-radius: 40px;
  padding: 1px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 30px;
  `};
`

const DoubleContainerInner = styled.div`
  background: #131118;
  width: 100%;
  padding: 32px 48px;
  border-radius: 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 24px;
    border-radius: 30px;
  `};
`

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [activeNetwork] = useActiveNetworkVersion()

  const [protocolData] = useProtocolData()
  const [transactions] = useProtocolTransactions()
  const { tokens, tvlFromAsset, loading } = useAssetVaultData()
  const { pools, pairs, capital, tvl, loading: ammLoading } = useAMMVaultData()

  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()

  // Hot fix to remove errors in TVL data while subgraph syncs.
  // const [chartData] = useProtocolChartData()

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
  }, [activeNetwork])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (liquidityHover === undefined && protocolData) {
      setLiquidityHover(protocolData.tvlUSD)
    }
  }, [liquidityHover, protocolData])

  // const allTokens = useAllTokenData()

  const tvlValue = useMemo(() => {
    return formatDollarAmount(tvlFromAsset, 2, true)
  }, [tvlFromAsset])

  return (
    <PageWrapper>
      <ThemedBackgroundGlobal backgroundColor={activeNetwork.bgColor} />
      <AutoColumn gap="16px">

        <DoubleContainer>
          <DoubleContainerInner>
            <RowBetween>
              <TYPE.title>Capital Supplied</TYPE.title>
              <TYPE.gradientTitle>TOTAL VAULT VALUE&nbsp;:&nbsp;{formatDollarAmount(Number(tvl))}</TYPE.gradientTitle>
            </RowBetween>
            <CapitalTable capitalData={capital} loading={ammLoading} />
          </DoubleContainerInner>
        </DoubleContainer>

        <DoubleContainer>
          <DoubleContainerInner>
            <RowBetween>
              <TYPE.title>Top Pools</TYPE.title>
              {/* <TYPE.gradientTitle>TOTAL VAULT VALUE&nbsp;:&nbsp;{formatDollarAmount(Number(poolTVL))}</TYPE.gradientTitle> */}
            </RowBetween>
            <PoolTables poolDatas={pools} loading={ammLoading} />
          </DoubleContainerInner>
        </DoubleContainer>

        <DoubleContainer>
          <DoubleContainerInner>
            <RowBetween>
              <TYPE.title>Top Pairs</TYPE.title>
              {/* <TYPE.gradientTitle>TOTAL VAULT VALUE&nbsp;:&nbsp;{formatDollarAmount(Number(pairTVL))}</TYPE.gradientTitle> */}
            </RowBetween>
            <PairTables pairDatas={pairs} loading={ammLoading} />
          </DoubleContainerInner>
        </DoubleContainer>

        <DoubleContainer>
          <DoubleContainerInner>
            <RowBetween>
              <TYPE.title>Asset Vault</TYPE.title>
              <TYPE.gradientTitle>TOTAL VAULT VALUE&nbsp;:&nbsp;{tvlValue}</TYPE.gradientTitle>
            </RowBetween>
            <TokenTable tokenDatas={tokens} />
          </DoubleContainerInner>
        </DoubleContainer>

        {/* keyword to search: fetchTopTransactions */}
        <DoubleContainer>
          <DoubleContainerInner>
            <RowBetween>
              <TYPE.title>Transactions</TYPE.title>
            </RowBetween>
            {transactions ? <TransactionsTable transactions={transactions} color={activeNetwork.primaryColor} /> : null}
          </DoubleContainerInner>
        </DoubleContainer>
      </AutoColumn>
    </PageWrapper>
  )
}
