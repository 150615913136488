import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'

const GLOBAL_TRANSACTIONS = gql`
  query transactions {
    assetHistories(first: 1000, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      id
      timestamp
      owner
      asset
      amount
      type
      tvl
    }
    asHistories: histories {
      id
      owner
      asset
      capital
      lpAmount
      action
      ammType
      bundle
      capitalAmount
      assetAmount
      timestamp
    }
  }
`

export const TOKENS_QUERY = gql(`
  query tokens {
    tokens {
      decimal
      id
      name
      symbol
    }
  }
`)

type TransactionEntry = {
  id: string
  timestamp: string
  owner: string
  asset: string
  amount: string
  type: string
  tvl?: string
}

type TransactionHistory = {
  id: string
  owner: string
  asset: string
  capital: string
  lpAmount: string
  action: string
  ammType: string
  bundle: string
  capitalAmount: string
  assetAmount: string
  timestamp: string
}

interface TransactionResults {
  assetHistories: TransactionEntry[]
  asHistories: TransactionHistory[]
}

export async function fetchTopTransactions(
  client: ApolloClient<NormalizedCacheObject>
): Promise<Transaction[] | undefined> {
  try {
    const { data, error, loading } = await client.query<TransactionResults>({
      query: GLOBAL_TRANSACTIONS,
      fetchPolicy: 'cache-first',
    })

    const { data: tokenMetadata } = await client.query({
      query: TOKENS_QUERY,
      fetchPolicy: 'cache-first',
    })

    const tokenData = tokenMetadata.tokens.map((token: any) => {
      return {
        address: token.id,
        name: token.name,
        decimal: token.decimal,
        symbol: token.symbol,
      }
    })
    if (error || loading || !data) {
      return undefined
    }

    const typeMap: any = {
      deposit: TransactionType.DEPOSIT,
      withdraw: TransactionType.WITHDRAW,
      borrow: TransactionType.BORROW,
      repay: TransactionType.REPAY,
      AddLiquidity: TransactionType.ADD_LIQUIDITY,
      RemoveLiquidity: TransactionType.REMOVE_LIQUIDITY,
      ImportLiquidity: TransactionType.IMPORT_LIQUIDITY,
      MigrateLiquidity: TransactionType.MIGRATE_LIQUIDITY,
    }

    const asHistories = data.asHistories.map((entry: TransactionHistory) => {
      const token = tokenData.find((item: any) => item.address === entry.asset)
      const capitalToken = tokenData.find((item: any) => item.address === entry.capital)

      return {
        type: typeMap[entry.action],
        hash: entry.id,
        timestamp: entry.timestamp,
        ammType: entry.ammType,
        sender: entry.owner,
        token0Decimal: capitalToken ? capitalToken.decimal : 18,
        token1Decimal: token ? token.decimal : 18,
        token0Symbol: capitalToken ? capitalToken.symbol : 'USD',
        token1Symbol: token ? token.symbol : 'USD',
        token0Address: entry.capital,
        token1Address: entry.asset,
        amountUSD: 0,
        amountToken0: entry.capitalAmount,
        amountToken1: entry.assetAmount,
        lpAmount: entry.lpAmount,
        tvl: undefined,
      }
    })

    const formatted = data.assetHistories.map((entry: any) => {
      const token = tokenData.filter((item: any) => item.address === entry.asset)[0]
      return {
        type: typeMap[entry.type],
        hash: entry.id,
        timestamp: entry.timestamp,
        sender: entry.owner,
        token0Decimal: token ? token.decimal : 18,
        token1Decimal: 18,
        token0Symbol: token ? token.symbol : 'USD',
        token1Symbol: '',
        token0Address: entry.asset,
        token1Address: '',
        amountUSD: entry.amount,
        amountToken0: entry.amount,
        amountToken1: entry.amount,
        lpAmount: entry.lpAmount,
        tvl: entry.tvl,
      }
    })

    return formatted.concat(asHistories).sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  } catch {
    return undefined
  }
}
